/* eslint-disable import/prefer-default-export */
import React from 'react';
import { MainColour } from '../../utils/variables';

import Placeholder from '../Placeholder';
import Loader from '../Placeholder/loader';

export const TitlePlaceholder = () => (
  <Loader
    style={{
      minHeight: `38px`,
      maxWidth: `240px`,
      marginTop: `15px`,
      marginBottom: `60px`,
    }}
  />
);

const FieldLabelPlaceholder = () => (
  <Placeholder
    width="120px"
    height="22px"
    colour="#000"
    style={{ marginBottom: `12px` }}
  />
);

export const FieldPlaceholder = () => (
  <>
    <FieldLabelPlaceholder />
    <Placeholder
      width="100%"
      height="58px"
      colour="#000"
      style={{ marginBottom: `10px` }}
    />
  </>
);

export const TextAreaPlaceholder = () => (
  <>
    <FieldLabelPlaceholder />
    <Placeholder
      width="100%"
      height="140px"
      colour="#000"
      style={{ marginBottom: `10px` }}
    />
  </>
);

export const CheckboxPlaceholder = () => (
  <div style={{ display: `flex`, alignItems: `center` }}>
    <Placeholder
      width="26px"
      height="26px"
      colour="#000"
      style={{ marginRight: `15px` }}
    />
    <Placeholder
      width="300px"
      height="20px"
      colour="#000"
      style={{ flexGrow: 1 }}
    />
  </div>
);

const RadioItem = () => (
  <div style={{ display: `flex`, alignItems: `center`, flex: `0 0 48%` }}>
    <FieldLabelPlaceholder />
    <Placeholder
      width="300px"
      height="20px"
      colour="#000"
      style={{ flexGrow: 1 }}
    />
  </div>
);

export const RadioPlaceholder = () => (
  <>
    <Placeholder
      width="70px"
      height="20px"
      colour="#000"
      style={{ marginBottom: `30px` }}
    />
    <div
      style={{
        display: `flex`,
        alignItems: `center`,
        justifyContent: `space-between`,
      }}
    >
      <RadioItem />
      <RadioItem />
    </div>
  </>
);

export const SubmitPlaceholder = () => (
  <Placeholder
    width="100%"
    height="59px"
    colour={MainColour}
    style={{ borderRadius: `100px` }}
  />
);
