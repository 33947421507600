import React, { useContext } from 'react';

import { FormContext } from '../context';
import ResponsiveContext from '../../Responsive/context';

import '../style.css';

import {
  CheckboxPlaceholder,
  FieldPlaceholder,
  RadioPlaceholder,
  SubmitPlaceholder,
  TextAreaPlaceholder,
} from '../placeholders';

import loadComponents from '../../Loadable';

const Checkbox = loadComponents('form-checkbox');
const Radio = loadComponents('form-radio');
const Input = loadComponents('form-input');
const TextArea = loadComponents('form-textarea');
const Select = loadComponents('form-select');
const Message = loadComponents('form-message');
const Country = loadComponents('form-country');
const Submit = loadComponents('form-submit');

export default function FormFields({ data }) {
  const { values, checkCondition, getFieldId } = useContext(FormContext);
  const { windowLG } = useContext(ResponsiveContext);

  return data
    .filter(x => !x.condition || (x.condition && checkCondition(x.condition)))
    .map((item, i) => {
      const key = `field${i}`;
      const isHidden = item.type === 'input' && item.inputType === 'hidden';

      const itemId = () => {
        if (item.id) {
          return item.id;
        }
        return item.name && getFieldId(item.name);
      };

      const fieldTitle = item.placeholder ? item.placeholder : item.name;
      const fieldLabel = item.label || item.name;

      const sharedProps = {
        name: itemId(),
        id: itemId(),
        label: fieldLabel,
        className: item.class,
        title: fieldTitle,
        'aria-label': fieldTitle,
        required: item.required,
        placeholder: item.placeholder,
        maxLength: item.maxlength,
        value: values[itemId()] || '',
        disabled: item.disabled,
      };

      const inputProps = {
        ...sharedProps,
        type: item.inputType,
        hint: !item.hideHint,
        reveal: item.reveal,
      };

      const radioProps = {
        ...inputProps,
        options: item.radioOptions,
      };

      const selectProps = {
        ...sharedProps,
        options: item.selectOptions,
      };

      return (
        <div
          key={key}
          className="field"
          style={{
            display: isHidden && 'none',
            padding: `0 0.625rem`,
            flex: `0 0 ${windowLG ? item.width : `100%`}`,
            maxWidth: `100%`,
            margin: `0 0 10px`,
          }}
        >
          {item.type === 'input' &&
            (() => {
              switch (item.inputType) {
                case 'checkbox':
                  return (
                    <Checkbox
                      field={inputProps}
                      fallback={<CheckboxPlaceholder />}
                    />
                  );
                case 'radio':
                  return (
                    <Radio field={radioProps} fallback={<RadioPlaceholder />} />
                  );
                default:
                  return (
                    <Input field={inputProps} fallback={<FieldPlaceholder />} />
                  );
              }
            })()}
          {item.type === 'country' && (
            <Country field={sharedProps} fallback={<FieldPlaceholder />} />
          )}
          {item.type === 'textarea' && (
            <TextArea field={sharedProps} fallback={<TextAreaPlaceholder />} />
          )}
          {item.type === 'select' && (
            <Select field={selectProps} fallback={<FieldPlaceholder />} />
          )}
          {item.type === 'message' && <Message message={item.message} />}
          {item.type === 'submit' && (
            <Submit label={item.buttonLabel} fallback={<SubmitPlaceholder />} />
          )}
        </div>
      );
    });
}
