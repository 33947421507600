import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Container from '../Container';
import Placeholder from '../Placeholder';

import './title.css';

import loadComponents from '../Loadable';
import loadLibrary from '../Loadable/library';
import Loader from '../Placeholder/loader';
import { TextColour } from '../../utils/variables';

const Title = loadComponents('h1');
const Img = loadLibrary('gatsby-image');

export default function PageTitle({ title, image, margin, style }) {
  const data = useStaticQuery(graphql`
    query DefaultHeaderQuery {
      wp {
        ...DefaultHeaderImage
      }
    }
  `);

  const defaultImage =
    data.wp.options.awwSettings.defaultHeaderImage.localFile.childImageSharp
      .gatsbyImageData;

  const backgroundImage = image || defaultImage;

  return (
    <div
      className="pageHeader"
      style={{
        // height: `240px`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        justifyContent: `center`,
        position: `relative`,
        backgroundColor: TextColour,
        marginBottom: margin /* || `5.625rem` */,
      }}
    >
      <Container className="content">
        <Title
          margin="0"
          colour="#fff"
          line={1}
          fallback={
            <Placeholder
              width="300px"
              height="40px"
              colour="#fff"
              // style={{ marginBottom: titleMargin }}
            />
          }
          style={{ ...style, textTransform: `uppercase` }}
        >
          {title}
        </Title>
      </Container>
      <div
        className="overlay"
        style={{ ...sharedStyles, backgroundColor: `rgba(0,0,0,0.75)` }}
      />
      <Img
        image={backgroundImage}
        alt={title}
        fallback={<Loader style={sharedStyles} />}
      />
    </div>
  );
}

const sharedStyles = {
  position: `absolute`,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};
